import { Button, Form, Input, Spin, Switch, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import {
  defaultInputValidation,
} from "../../../../utils/formValidationRules";
import { Store, StoreValue } from "antd/es/form/interface";
import useApiPost from "../../../../hooks/useApiPost";
import { toast } from "react-toastify";
import { AppContext } from "../../../../App";
import { useNavigate, useParams } from "react-router-dom";

interface formProp {
  name: string;
  isActive: boolean;
}
interface apiCallProp {
  url: string;
  method: "POST" | "GET" | "PATCH";
  payload: {};
  result?: (res: any) => void;
}

function ProductStatusForm() {
  const navigate = useNavigate();
  const {productStatusId} = useParams<{productStatusId:string}>();

  const [form] = Form.useForm();
  const { request } = useApiPost();
  const { projectId } = useContext(AppContext);

  const [isloading, setLoading] = useState(false);
  const [formValue, setInitialValue] = useState<formProp>({
    name: "",
    isActive: false,
  });

  const handleComeBack = () => navigate("/admin/product-status");
  const title = !!productStatusId ? "Update Product Status" : "Add Product Status";

  const apiCalls = async ({
    url,
    method = "POST",
    payload,
    result,
  }: apiCallProp) => {
    setLoading(true);
    try {
      const response = await request(url, method, payload);
      if (response?.status === 201) {
       
        toast.success(response?.message, {
          position: "top-right",
        });
        handleComeBack();
      }else if(response?.status === 200){
         result?.(response);
      }
       else {
        let error = response?.message?.error
        ? response?.message?.error
        : response?.message
        ? response?.message
        : "something went wrong!!";
        
        toast.error(error, {
          position: "top-right",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(()=>{
    if(!!productStatusId){
      apiCalls({
        url: "/office/master-product-status/details",
        method: "POST",
        payload:{
          project_id:projectId,
          id:productStatusId,
        },
        result(res) { 
          const[data] = res?.data;
          form.setFieldsValue({
            name: data?.name,
            isActive: data?.is_active,
          });
        },
      });
    }
    
  },[productStatusId,projectId])

  const addStatus = (payload: any) => {
    apiCalls({
      url: "/office/master-product-status/add",
      method: "POST",
      payload,
    });
  };
  const updateStatus = (payload: any) => {
    apiCalls({
      url: "/office/master-product-status/update",
      method: "POST",
      payload,
      result(res) { 
        toast.success(res?.message, {
          position: "top-right",
        });
        handleComeBack();
      },
    });
  };

  const onFinish = (e: any) => {
    
    let name = e?.name?.trim();
    let status = e?.isActive;

    if(!!productStatusId){
      updateStatus({
        name: name,
        is_active: status,
        project_id: projectId,
        id:productStatusId,
      });
    }
    else{
      addStatus({
        name: name,
        is_active: status,
        project_id: projectId,
      });
    }
  };

  if (isloading) {
    return (
      <Spin
        tip="Loading..."
        spinning={isloading}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "70%",
        }}
      />
    );
  }

  return (
    <>
      <Typography.Title>{title}</Typography.Title>
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        initialValues={formValue}
        onFinish={onFinish}
      >
        <Form.Item
          label="Name"
          name={"name"}
          rules={defaultInputValidation}
          required
          
        >
          <Input  disabled={!!productStatusId} />
        </Form.Item>
        <Form.Item label="Is Active" valuePropName="checked" name={"isActive"}>
          <Switch />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}

export default ProductStatusForm;
