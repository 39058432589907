import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
    Form,
    Button,
    Typography,
    Table
} from "antd";
import readXlsxFile from "read-excel-file";
import { AppContext } from "../../../../App";
import useApiPost from "../../../../hooks/useApiPost";
import { toast } from "react-toastify";

export interface SheetDataSet {
    product_code: string;
    product_status: string;
}

const ProductBulkMapping = () => {

    const [form] = Form.useForm();
    const { projectId } = useContext(AppContext);;
    const [errorCount, seterrorCount] = useState<any>(0);
    const [products, setProducts] = useState<any[]>([]);
    const headerText = "Bulk Product Status Mapping";
    const navigate = useNavigate();
    const { request, setError } = useApiPost();
    const [isLoading, setIsLoading] = useState(false);
    const [isFileSelected, setisFileSelected] = useState(false);

    const handleComeBack = () => {
        navigate("/admin/products");
    };

    const handleSave = async () => {
        if (!projectId) return
        if (products.length === 0) return notifyError();
        setIsLoading(true);
        const res = await request("/office/products/bulkProductStatus", "POST", {
            project_id: projectId,
            product_data: products,
        });
        setIsLoading(false);
        if (res?.data?.errors) {
            setProducts(res?.data?.errors)
        } else {
            if (res?.status == 200) {
                notifySuccess();
                handleComeBack();
            } else {
                toast.error(res?.message, {
                    position: "bottom-center"
                });
            }
        }
    };
    const notifyError = () => {
        toast.error("Please choose excel file", {
            position: "bottom-center"
        });
    };

    const notifySuccess = () => {
        toast.info("File uploaded successfully", {
            position: "bottom-center"
        });
    };

    const validateObjectFieldsWithEarlyExit = (value: any) => {
        if (value === undefined || value === null || value === '') {
            return false;
        }
        return true;
    };

    const handleUploadBatchFile = async (e: any) => {
        seterrorCount(0);
        setProducts([]);
        setisFileSelected(false)
        const file = e.target.files[0];
        await readXlsxFile(file).then(async (rows) => {
            let filteredData: any = [];
            const encounteredCombinations = new Set();

            rows.forEach((item: any, index: number) => {
                if (index > 0) {
                    const product_code = item[0]?.toString().trim();
                    const product_status = item[1]?.toString().trim();
                    const sheetDataRow: SheetDataSet = {
                        product_code: product_code,
                        product_status: product_status
                    };
                    const combination =
                        product_code +
                        product_status;
                    if (validateObjectFieldsWithEarlyExit(sheetDataRow.product_code) &&
                        !encounteredCombinations.has(combination) ) {
                        filteredData.push(sheetDataRow);
                        encounteredCombinations.add(combination);
                    } else {
                        seterrorCount((prevCount: number) => prevCount + 1);
                    }
                }
            });
            if (filteredData.length > 0) {
                setProducts(filteredData);
            } else {
                setisFileSelected(true)
            }
        });
    };

    const hasErrors = products.some((entry) => entry.error_msg);

    return (
        <>
            <Typography.Title>{headerText}</Typography.Title>
            <Form
                form={form}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
            >
                <Table
                    rowKey="order"
                    style={{
                        fontSize: "12px",
                    }}
                    dataSource={products}
                    columns={[
                        {
                            title: "EAN",
                            dataIndex: "product_code",
                            key: "product_code",
                        },
                        {
                            title: "Status",
                            dataIndex: "product_status",
                            key: "product_status",
                        },
                        ...(hasErrors
                            ? [
                                {
                                    title: "Error",
                                    dataIndex: "error_msg",
                                    key: "error_msg",
                                    render: (text: any) => (
                                        <div style={{ color: text ? "red" : "green" }}>
                                            {text || "No errors"}
                                        </div>
                                    ),
                                },
                            ]
                            : []),
                    ]}
                />
                <div style={{ marginTop: 15 }}>

                    <input
                        type="file"
                        id="input"
                        accept=".xlsx, .xls"
                        onChange={(e) => {
                            handleUploadBatchFile(e);
                        }}
                    />
                </div>
                {errorCount > 0 && <div style={{ color: "red", marginTop: 10 }}>
                    {`Due to duplicate or incorrect data, ${errorCount} records cannot be uploaded. `}
                </div>}
                {isFileSelected && !products.length && <div style={{ color: "red", margin: 10 }}>
                    {`No Records Found.`}
                </div>}
                <Button
                    type="primary"
                    style={{ marginTop: 15 }}
                    onClick={handleComeBack}
                >
                    Go back
                </Button>

                <Button type="primary" style={{ marginLeft: 15 }} htmlType="submit" onClick={handleSave} loading={isLoading}>
                    Save
                </Button>
            </Form>
        </>
    );
}

export default ProductBulkMapping