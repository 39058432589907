import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  Button,
  Switch,
  Select,
  TableProps,
  Form,
  Typography,
  Popconfirm,
  InputNumber,
  Input,
} from "antd";
import { AppContext } from "../../../../App";
import { IOption } from "../types";
import { DataType } from "./sku-basket-types";
// import {
//   getSkuBasketOptions,
//   getSkuBaskets,
// } from "../../../../api/office/basket";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
// import {
//   confirmBasketDeletion,
//   confirmBasketValidation,
//   updateSkuItem,
// } from "../../../../api/office/sku-baskets";
import useApiPost from "../../../../hooks/useApiPost";

const SkuBasketList = () => {
  const { request, setError } = useApiPost();
  const appContext = useContext(AppContext);
  const { projectId } = appContext;
  const originData: DataType[] = [];
  const [chainOptions, setChainOptions] = useState<IOption[]>([]);
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const baskets = await request("/office/sku-baskets", "POST", {
        projectId,
      });
      const { chains, outletTypes } = await request(
        "/office/sku-baskets/options/creation/basket",
        "POST",
        {
          projectId,
        }
      );
      // const baskets = await getSkuBaskets(projectId);
      // const { chains, outletTypes } = await getSkuBasketOptions(projectId);

      if (!baskets || !baskets.length) {
        setIsLoading(false);
        return;
      }

      setChainOptions(chains);
      // setChainOptions(sortedChains);
      setOutletTypeOptions(outletTypes);

      setBaskets(baskets);
      setIsLoading(false);
    };
    fetchData();
  }, [projectId]);

  const [form] = Form.useForm();
  const [data, setData] = useState(originData);
  const [editingKey, setEditingKey] = useState("");

  const isEditing = (record: DataType) => record.id === editingKey;

  const edit = (record: Partial<DataType> & { key: React.Key }) => {
    if (!record.id) return;
    form.setFieldsValue({ ...record });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const [isLoading, setIsLoading] = useState(false);
  const [baskets, setBaskets] = useState<DataType[]>([]);
  // const [chainOptions, setChainOptions] = useState<ISearchOption[]>([]);
  const [outletTypeOptions, setOutletTypeOptions] = useState<IOption[]>([]);
  const [isOnlyActiveVisible, setIsOnlyActiveVisible] = useState(true);

  const dateToStart = new Date();
  dateToStart.setHours(0, 0, 0, 0);
  const dateFromEnd = new Date();
  dateFromEnd.setHours(23, 59, 59, 999);

  const handleTo = () => navigate("/admin/sku-basket/new");

  const handleConfirmSelected = async () => {
    // await confirmBasketValidation({ entries: selectedRowKeys });
    await request("/office/sku-baskets/confirm-validation", "POST", {
      entries: selectedRowKeys,
    });

    const newBaskets = baskets.map((basket) => {
      if (selectedRowKeys.includes(basket.id)) {
        return { ...basket, isImportValidationRequired: false };
      }
      return basket;
    });

    setBaskets(newBaskets);
    setSelectedRowKeys([]);
  };

  const handleDeleteSelected = async () => {
    await request("/office/sku-baskets/confirm-deletion", "POST", {
      entries: selectedRowKeys,
    });
    // await confirmBasketDeletion({ entries: selectedRowKeys });

    const newBaskets = baskets.filter(
      (basket) => !selectedRowKeys.includes(basket.id)
    );

    setBaskets(newBaskets);
    setSelectedRowKeys([]);
  };

  const [filteredInfo, setFilteredInfo] = useState<
    Record<string, FilterValue | null>
  >({});
  const [sortedInfo, setSortedInfo] = useState<SorterResult<DataType>>({});
  const handleChange: TableProps<DataType>["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter as SorterResult<DataType>);
  };

  interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: "number" | "text";
    record: DataType;
    index: number;
    children: React.ReactNode;
  }

  const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
      inputType === "number" ? (
        <InputNumber style={{ fontSize: "12px" }} step="0.01" min="0" />
      ) : (
        <Input style={{ fontSize: "12px" }} />
      );

    if (dataIndex === "chain") {
      return (
        <td {...restProps}>
          {editing ? (
            <Form.Item name={dataIndex} style={{ margin: 0 }}>
              <Select
                style={{ width: "100%", minWidth: "100px" }}
                options={chainOptions}
                placeholder="Select Chain"
              />
            </Form.Item>
          ) : (
            children
          )}
        </td>
      );
    } else if (dataIndex === "outletType") {
      return (
        <td {...restProps}>
          {editing ? (
            <Form.Item name={dataIndex} style={{ margin: 0, fontSize: 12 }}>
              <Select
                style={{ width: "100%", fontSize: "12px" }}
                options={outletTypeOptions}
                placeholder="Outlet type"
              />
            </Form.Item>
          ) : (
            children
          )}
        </td>
      );
    } else if (dataIndex === "isActive") {
      return (
        <td {...restProps}>
          {editing ? (
            <Form.Item name={dataIndex} style={{ margin: 0 }}>
              <Switch
                checkedChildren="Yes"
                unCheckedChildren="No"
                defaultChecked={record.isActive}
              />
            </Form.Item>
          ) : (
            children
          )}
        </td>
      );
    }

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            // rules={[
            //   {
            //     required: true,
            //     message: `Please Input ${title}!`,
            //   },
            // ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const save = async (key: React.Key) => {
    try {
      let row = (await form.validateFields()) as DataType;
      const selectedChain = chainOptions.find(
        (item) => item.value === row.chain
      );

      const selectedOutletType = outletTypeOptions.find(
        (item) => item.value === row.outletType
      );

      const newData = [...baskets];
      const index = newData.findIndex((item) => item.id === editingKey);

      row = {
        ...row,
        chain: selectedChain ? selectedChain.label : newData[index].chain,
        chainId: selectedChain ? selectedChain.value : newData[index].chainId,
        outletType: selectedOutletType
          ? selectedOutletType.label
          : newData[index].outletType,
        outletTypeId: selectedOutletType
          ? selectedOutletType.value
          : newData[index].outletTypeId,
      };

      if (index > -1) {
        await request("/office/sku-baskets/update", "POST", {
          ...row,
          project_id: projectId,
          basketId: editingKey,
        });
        
        /* FOR FUTURE REF
        await updateSkuItem({
          ...row,
          project_id: projectId,
          basketId: editingKey,
        });*/

        const item = {
          ...newData[index],
          chain: selectedChain ? selectedChain.label : newData[index].chain,
          chainId: selectedChain ? selectedChain.value : newData[index].chainId,
          outletType: selectedOutletType
            ? selectedOutletType.label
            : newData[index].outletType,
          outletTypeId: selectedOutletType
            ? selectedOutletType.value
            : newData[index].outletTypeId,
        };

        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setBaskets(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setBaskets(newData);
        setEditingKey("");
      }

    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };
  const columns = [
    {
      title: "Created at",
      dataIndex: "created_at",
      key: "created_at",
      isEditable: false,
      render: (date: any) => <div>{new Date(date).toLocaleString()}</div>,
    },
    {
      title: "Active",
      dataIndex: "isActive",
      isEditable: true,
      render: (val: any) => (
        <div>
          <Switch defaultChecked={val} disabled />
        </div>
      ),
    },
    {
      title: "Chain",
      dataIndex: "chain",
      key: "chain",
      filters: chainOptions,
      filteredValue: filteredInfo.chain || null,
      //@ts-ignore
      onFilter: (value: string, record) => record.chainId === value,
      // sorter: (a, b) => a.chain.length - b.chain.length,
      sortOrder: sortedInfo.columnKey === "chain" ? sortedInfo.order : null,
      isEditable: true,
      render: (text: any) => {
        return <div>{text} </div>;
      },
    },
    {
      title: "Outlet Type",
      dataIndex: "outletType",
      key: "outletType",
      filters: outletTypeOptions,
      filteredValue: filteredInfo.outletType || null,
      //@ts-ignore
      onFilter: (value: string, record) => {
        return record.outletTypeId === value;
      },
      // sorter: (a, b) => a.outletType.length - b.outletType.length,
      sortOrder:
        sortedInfo.columnKey === "outletType" ? sortedInfo.order : null,
      isEditable: true,
      render: (text: any) => <div>{text} </div>,
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      isEditable: false,
      render: (text: any) => <div>{text}</div>,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "product",
      isEditable: false,
      render: (text: any) => <div>{text}</div>,
    },
    {
      title: "EAN",
      dataIndex: "EAN",
      key: "EAN",
      isEditable: false,
      render: (text: any) => <div>{text}</div>,
    },
    {
      title: "MSH",
      dataIndex: "MSH",
      key: "MSH",
      isEditable: true,
      render: (text: any) => <div>{text}</div>,
    },
    {
      title: "RSP",
      dataIndex: "RSP",
      key: "RSP",
      isEditable: true,
      render: (text: any) => <div>{text}</div>,
    },
    // {
    //   title: "Start",
    //   dataIndex: "startDate",
    //   key: "startDate",
    //   isEditable: false,
    //   render: (date: any) => <div>{`${new Date(date).toDateString()}`}</div>,
    // },
    // {
    //   title: "End",
    //   dataIndex: "endDate",
    //   key: "endDate",
    //   isEditable: false,
    //   render: (date: any) => <div>{`${new Date(date).toDateString()}`}</div>,
    // },
    {
      title: "When",
      dataIndex: "endDate",
      key: "endDate",
      isEditable: false,
      render: (date: any, row: DataType) => (
        <div>{`${new Date(row.startDate).toDateString().slice(4)} - ${new Date(
          row.endDate
        )
          .toDateString()
          .slice(4)}`}</div>
      ),
    },
    {
      title: "Operation",
      dataIndex: "operation",
      render: (_: any, record: DataType) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{ marginRight: 8 }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   key: "action",
    //   render: (_, record) => (
    //     <Space size="middle" key={record.basketId}>
    //       <Button type="primary" onClick={() => props.onUpdate(record.id)}>
    //         Update
    //       </Button>
    //     </Space>
    //   ),
    // },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.isEditable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        inputType:
          col.dataIndex === "MSH" || col.dataIndex === "RSP"
            ? "number"
            : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <>
      <div>
        <div
          style={{
            paddingBottom: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {/* <Switch
            checkedChildren="Active"
            unCheckedChildren="All"
            defaultChecked
            onChange={() => setIsOnlyActiveVisible(!isOnlyActiveVisible)}
          /> */}
          {/* <Button type="primary" onClick={() => getData()}>
            Refresh
          </Button> */}
        </div>
      </div>
      <Form form={form} component={false}>
        <Table
          loading={isLoading}
          rowSelection={rowSelection}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          //@ts-ignore
          columns={mergedColumns}
          dataSource={baskets}
          rowKey="id"
          onChange={handleChange}
          pagination={{
            defaultPageSize: 25,
          }}
          rowClassName={(record, index) =>
            record.isImportValidationRequired ? "not-verified" : ""
          }
          bordered
        />
      </Form>

      <Button type="primary" onClick={handleTo}>
        Add
      </Button>

      <Button
        disabled={selectedRowKeys.length === 0}
        style={{ marginLeft: 10 }}
        type="primary"
        onClick={handleConfirmSelected}
      >
        Confirm selected
      </Button>

      <Button
        disabled={selectedRowKeys.length === 0}
        style={{ marginLeft: 10 }}
        type="primary"
        onClick={handleDeleteSelected}
        danger
      >
        Delete selected
      </Button>
    </>
  );
};

export default SkuBasketList;
