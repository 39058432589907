import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Space, Table, Button, Input, InputRef, Modal } from "antd";
import type { ColumnsType, ColumnType } from "antd/es/table";
import { AppContext } from "../../../../App";
import type { FilterConfirmProps } from "antd/es/table/interface";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import useApiPost from "../../../../hooks/useApiPost";

type DataIndex = keyof DataType;

interface DataType {
  id: string;
  key: string;
  name: string;
  phone_number: string;
  email: string;
  description: string;
  shortDescription: string;
  productType: string;
  manufacturer: string;
  EAN: string;
}
// 104063171
const ProductsPage = () => {
  const searchInput = useRef<InputRef>(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const { request, setError } = useApiPost();

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const location = useLocation();
  const first = location.pathname.split("/")[1];
  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }: any) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      const exists = record[dataIndex] ? record[dataIndex] : false;

      if (!exists) {
        return false;
      }
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        //@ts-ignore
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = (props: any): ColumnsType<DataType> => [
    {
      title: "Name",
      dataIndex: "description",
      key: "description",
      ...getColumnSearchProps("description"),
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Description",
      dataIndex: "shortDescription",
      key: "shortDescription",
      ...getColumnSearchProps("shortDescription"),
      render: (text) => <div>{text}</div>,
    },
    {
      title: "EAN",
      dataIndex: "EAN",
      key: "ean",
      ...getColumnSearchProps("EAN"),
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
      key: "manufacturer",
      ...getColumnSearchProps("manufacturer"),
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Product Type",
      dataIndex: "productType",
      key: "productType",
      ...getColumnSearchProps("productType"),
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Space size="middle" key={record.id}>
          <Button type="primary" onClick={() => props.onUpdate(record.id)}>
            Update
          </Button>
          <Button
            type="primary"
            danger
            onClick={() => props.onDelete(record.id)}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];
  const appContext = useContext(AppContext);

  const { projectId } = appContext;

  const [isLoading, setIsLoading] = useState(false);
  const [allProducts, setAllProducts] = useState<DataType[]>([]);
  let navigate = useNavigate();

  const handleTo = () => {
    navigate(`/${first}/product/new`);
  };

  const fetchAllProducts = async () => {
    setIsLoading(true);
    const allProducts = await request("/office/products", "POST");
    setAllProducts(allProducts);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAllProducts();
  }, [projectId]);

  const handleUpdate = async (id: string) => {
    navigate(`/${first}/product/edit/${id}`);
  };
  const handleConfirmDelete = async () => {
    setIsModalOpen(false);
    await request("/office/products/archive", "POST", {
      id: selectedId,
    });

    setAllProducts(allProducts.filter((product) => product.id !== selectedId));
    setSelectedId("");
  };

  const handleSelectDelete = async (id: string) => {
    setSelectedId(id);
    setIsModalOpen(true);
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
    setSelectedId("");
  };

  const handleBulkMapping = () => {
    navigate(`/admin/product/product-status-bulk-mapping`);
  }
  

  return (
    <>
      <Modal
        title="Confirmation"
        open={isModalOpen}
        onOk={handleConfirmDelete}
        onCancel={handleModalCancel}
        centered
        footer={[
          <Button key="back" onClick={handleModalCancel}>
            Back
          </Button>,
          <Button key="submit" type="primary" onClick={handleConfirmDelete}>
            Confirm
          </Button>,
        ]}
      >
        <div>
          Are you sure you want to delete this item? This action cannot be
          undone.
        </div>
      </Modal>
      <Table
        loading={isLoading}
        columns={columns({
          onUpdate: handleUpdate,
          onDelete: handleSelectDelete,
        })}
        dataSource={allProducts}
        rowKey="key"
      />

      <Button type="primary" onClick={handleTo}>
        Add
      </Button>
      <Button type="primary" style={{marginLeft: 15}} onClick={handleBulkMapping}>
        Bulk Product Status Mapping
      </Button>
    </>
  );
};

export default ProductsPage;
