import { useContext, useEffect, useState } from "react";
import {
  Button,
  Form,
  Select,
  Typography,
  Switch
} from "antd";
import { AppContext } from "../../../../App";
import useApiPost from "../../../../hooks/useApiPost";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

const StockReportSchedulerForm = () => {
  const { id } = useParams<{ id: string }>();
  const { projectId } = useContext(AppContext);
  const reports: any = [{
    value: "7 Days",
    label: "7 Days"
  },
  {
    value: "14 Days",
    label: "14 Days"
  },
  {
    value: "30 Days",
    label: "30 Days"
  }]
  const [chains, setChains] = useState<any[]>([]);
  const [regionData, setRegionData] = useState<any[]>([]);
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { request, setError } = useApiPost();
  const navigate = useNavigate();
  const [selectedChains, setSelectedChains] = useState<any[]>([]);
  const [selectedRegion, setSelectedRegion] = useState<any[]>([]);
  const [selectedReport, setSelectedReport] = useState<any>();
  const headerTitle = id ? "Edit Stock Report Scheduler" : "Add Stock Report Scheduler"

  useEffect(() => {
    fetchOptions();
  }, [])

  const fetchOptions = async () => {
    try {
      const regionsData = await request(`/office/regions`, "POST", {
        projectId,
      });
      const flattenedArray = flattenChildren(regionsData?.data?.regions);
      setRegionData(flattenedArray);

      const chainData = await request(`/office/chain`, "POST", {
        projectId,
      });

      setChains(chainData.data);

      if(id){
        const response = await request(`/office/stock-report-schedule/detail`, "POST", {
          id: id,
          project_id: projectId,
        });
        if(response.status == 200){
          const stockReportSchdule = response.data
          setIsActive(stockReportSchdule.report_meta?.is_active)
          if(stockReportSchdule.report_meta?.report_name){
            setSelectedReport(stockReportSchdule.report_meta?.report_name)
          }
          if(stockReportSchdule.chains){
            const selectedValues = stockReportSchdule.chains.map(function(value: any) {
              return value.chain_id;
            });
            setSelectedChains(selectedValues)
          }
          if(stockReportSchdule.regions){
            const selectedValues = stockReportSchdule.regions.map(function(value: any) {
              return value.region_id;
            });
            setSelectedRegion(selectedValues)
          }
        }
      }

    } catch (error) {
      console.error('Error fetching options:', error);
    }
  }

  function flattenChildren(data: any) {
    let result: any = [];
    data.forEach((item: any) => {
      result.push(item); // Add the current item
      if (item.children && item.children.length > 0) {
        // result = result.concat(flattenChildren(item.children)); // Recursively add child items
        item.children.forEach((child: any) => {
          result.push(child)
        });
      }
    });
    return result;
  }

  const handlesubmit = async () => {
    if (!selectedReport) return alert("Please select Report Type")
    if (selectedChains.length <= 0) return alert("Please select Chain")
    if (selectedRegion.length <= 0) return alert("Please select Region")
    setIsLoading(true);
    try {
      const data = {
        project_id: projectId,
        report_name: selectedReport,
        chain_id: selectedChains,
        region_id: selectedRegion,
        is_active: isActive
      }
      let response = null
      if (id) {
        response = await request(`/office/stock-report-schedule/update`, "POST", {...data, id});
      } else {
        response = await request(`/office/stock-report-schedule/add`, "POST", data); 
      }
      if (response?.status == 200 || response?.status == 201) {
        toast.success(response?.message, {
          position: "top-right",
        });
        navigate("/admin/stock-report-scheduler");
      } else {
        toast.error(response?.message, {
          position: "top-right",
        });
      }
      setIsLoading(false)
    } catch {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Typography.Title>{headerTitle}</Typography.Title>
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        onFinish={handlesubmit}
      >
        <Form.Item label="Report Type">
          <Select
            allowClear
            placeholder="Please select"
            options={reports}
            value={selectedReport}
            disabled={!!id}
            onChange={(e) => setSelectedReport(e)}
          />
        </Form.Item>
        <Form.Item label="Chains">
          <Select
            allowClear
            mode="multiple"
            placeholder="Please select"
            options={chains}
            showSearch
            value={selectedChains}
            filterOption={(input, option) =>
              (option?.label?.toString() ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            onChange={(e) => setSelectedChains(e)}
          />
        </Form.Item>

        <Form.Item label="Region" >
          <Select
            allowClear
            mode="multiple"
            placeholder="Please select"
            showSearch
            options={regionData}
            value={selectedRegion}
            filterOption={(input, option) =>
              (option?.label?.toString() ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            onChange={(e) => setSelectedRegion(e)}
          />
        </Form.Item>
        <Form.Item label="Is active" name="isActive">
          <Switch checked={isActive} onChange={setIsActive} />
        </Form.Item>

        <Form.Item>
          <Button
            htmlType="submit"
            type="primary"
            loading={isLoading}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default StockReportSchedulerForm